window.etInit = function () {

	window.setResponsiveType([
		['pc-b', 1440, 1920],
		['pc-m', 1144, 1440],
		['pc-s', 767, 1144],
		['mobile', false, 767]
	])

	window.etUse = {
		popup: etlib.eventCall('.popup_open', etui.ajaxPopup.init)({
			openSel: '.popup_open',
			closeSel: '.btn_close',
			popupSel: '.ly_popup'
		}),
		channelHelp: etlib.eventCall('.pos_chn', etui.channelHelp)({btn:'.pos_chn'}),
		broadCastTab: etlib.eventCall('.ui_tab', etui.tab.init)({
			wrap: '.ui_tab',
			tab: '.deco_ttl',
			cont: '.tab_content',
			onMoveAfter: (function () {
				let contTabSwiper = new Array($('.tab_content').length);
				let $freeTab = $('.ui_freetab');
				$(window)
					.on('pc-b.freetab.resize pc-m.freetab.resize pc-s.freetab.resize', function () {
						$freeTab.addClass('swiper-no-swiping')
					}).on('mobile.freetab.resize', function () {
						$freeTab.removeClass('swiper-no-swiping')
					}).trigger(responseType + '.freetab.resize')

				return function (idx, _tab, cont) {
					let $freeTab = $(cont).find('.ui_freetab');
					window.setTimeout(function () {
						if(!contTabSwiper[idx]){
							contTabSwiper[idx] = etlib.eventCall($freeTab, etui.freeTab.init)({
								contain: $(cont).find('.ui_freetab').get(0),
								wrap: '.freetab_wrap',
								item: '.freetab_item'
							});
							if(idx === 0){
								window.broadTabSwiper = contTabSwiper[0];
								window.broadTabSwiper.slideTo($freeTab.find('.freetab_item.on').index()-1);
							}
						}
					}, 0)
				}
			})(),
			initIndex: 0,
		}),
		channelTab: etlib.eventCall('.ui_tab', etui.tab.init)({
			wrap: '.ui_channel_tab',
			tab: '.chn_tab li',
			cont: '.wrap_content',
			onMoveAfter(idx) {
				$('.mob_tb').find('.wrap_content').hide().eq(idx).show();
			},
			initIndex: 0,
		}),
		goTop: etlib.eventCall('.go_top', etui.goTop)({ topBtn: '.go_top' }),
		datepicker: etlib.eventCall('.btn_date', etui.datepicker)({ btn: '.btn_date', input: '#datepicker' }),
		listSwiper: etlib.eventCall('.list_swiper', etui.listSwiper)({ el: '.list_swiper' }),
		showMore: etlib.eventCall('.bx_info_programm', etui.more)(),
		bannerSrcChange: etlib.eventCall('.bx_bnr', etui.srcChange)({imgs:'.bx_bnr img'}),
		detailSrcChange: etlib.eventCall('.btn_go', etui.srcChange)({imgs:'.btn_go img'}),
		headerFixed: etlib.eventCall('#head_m', etui.headerFixed)({header:'#head_m'})

	};

	for (let key in window.etUse) {
		if (window.etUse.hasOwnProperty(key)) {
			window.etUse[key] === undefined ? delete window.etUse[key] : undefined;
		}
	}
};

Math.log10 = Math.log10 || function (x) {
	return Math.log(x) * Math.LOG10E;
};
/*** etlib ***/
(function ($) {
	$.fn.eventCall = function (fn) {return lib.eventCall(this, fn);};
	$.fn.hasScrollBar = function () {
		return (this.prop("scrollHeight") === 0 && this.prop("clientHeight") === 0)
			|| (this.prop("scrollHeight") > this.prop("clientHeight"));
	};
	let noop = () => undefined;
	let lib = window.etlib = {
		// 18.6.1 sel 가 존재시 작동 고차함수
		// ex1) lib.eventCall('.selector', fn)()
		// ex2) lib.eventCall('.selector', fn)(arg1, arg2) => return fn(arg1, arg2)
		eventCall(sel, fn) {
			let $sel = $(sel);
			return $(sel).length > 0 && fn ?
				(...args) => fn.apply($sel, [].slice.call(args)) : noop
		},
		isIE8: $('html').hasClass('ie8'),
		isIE: (function detectIE() {
			var ua = window.navigator.userAgent;

			// Test values; Uncomment to check result …

			// IE 10
			// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
			// IE 11
			// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
			// Edge 12 (Spartan)
			// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
			// Edge 13
			// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

			var msie = ua.indexOf('MSIE ');
			if (msie > 0) {
				// IE 10 or older => return version number
				return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
			}

			var trident = ua.indexOf('Trident/');
			if (trident > 0) {
				// IE 11 => return version number
				var rv = ua.indexOf('rv:');
				return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
			}

			var edge = ua.indexOf('Edge/');
			if (edge > 0) {
				// Edge (IE 12+) => return version number
				return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
			}

			// other browser
			return false;
		})(),
		isIOS: (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera) && !window.MSStream),
		windowScroll: (function windowScroll() {
			let body = document.body,
				html = document.documentElement,
				beforeScroll = 0,
				beforePosition, beforeTop, beforeOverflow;

			function getScrollY() {
				let supportPageOffset = window.pageXOffset !== undefined;
				let isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
				return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
			}

			return {
				isBlock: false,
				block() {
					[beforeScroll, beforePosition, beforeTop, beforeOverflow] =
						[getScrollY(), body.style.position, body.style.top, body.style.overflow];

					body.style.width = '100%';
					body.style.position = 'fixed';
					body.style.top = -beforeScroll + 'px';
					html.style.overflowY = Math.max(body.scrollHeight, body.offsetHeight,
						html.clientHeight, html.scrollHeight, html.offsetHeight) > window.innerHeight ? 'scroll' : '';
					this.isBlock = true;
				},
				unBlock() {
					body.style.width = '';
					body.style.position = beforePosition;
					body.style.top = beforeTop;
					html.style.overflowY = '';
					window.scrollTo(0, beforeScroll);
					this.isBlock = false;
				}
			}
		})(),
		throttle: function (func, limit) {
			var lastFunc
			var lastRan
			return function () {
				var context = this
				var args = arguments
				if (!lastRan) {
					func.apply(context, args)
					lastRan = Date.now()
				} else {
					clearTimeout(lastFunc)
					lastFunc = setTimeout(function () {
						if ((Date.now() - lastRan) >= limit) {
							func.apply(context, args)
							lastRan = Date.now()
						}
					}, limit - (Date.now() - lastRan))
				}
			}
		},
		debounce: function (func, delay) {
			var inDebounce
			return function () {
				var context = this
				var args = arguments
				clearTimeout(inDebounce)
				inDebounce = setTimeout(() => func.apply(context, args), delay)
			}
		},
		logger: (function () {
			var o = {};
			var storage = window.localStorage;
			var getStorage = function () {
				var st = storage && storage.getItem('logger');
				return st ? st : false;
			};
			var setStorage = function (setting) {
				if (storage) {
					storage.setItem('logger', setting);
					window.logger.error = levelLog(console.error);
					window.logger.warn = levelLog(console.warn);
					window.logger.log = levelLog(console.log);
					window.logger.group = levelLog(console.group);
					window.logger.groupEnd = levelLog(console.groupEnd);
				}
			};

			var useLogger = getStorage();
			var levelLog = function (fn) {
				if (useLogger === 'undefined' || useLogger === 'false' || useLogger === '0' || !useLogger) {
					return () => {};
				}
				return fn;
			}

			return {
				getStorage,
				setStorage,
				error: levelLog(console.error),
				warn: levelLog(console.warn),
				log: levelLog(console.log),
				group: levelLog(console.group),
				groupEnd: levelLog(console.groupEnd),
			}
		})()
	};
	window.logger = etlib.logger;


	/*** RESPONSIVE WEB EVENT EMITTER ***/
	/*** 2018-7-16 Hoejun ***/

	// Example - 이벤트 등록시 사이즈별 기능 분화
	// ex) setResponsiveType([['pc', 1024, false], ['tablet', 768, 1023], ['mobile', false, 767]])
	// $('.myDiv').on('mobile.click tablet.click', ...)
	// $('.myDiv').on('pc.click', ...)
	// $('.myDiv').trigger('click')

	function RESPONSIVE_WEB() {
		// else - pc
		let alltype, type, $body = $('body'), $window = $(window), $document = $(document), beforeWidth = 0;

		let defaultType = [['pc', 1024, 999999], ['tablet', 768, 1023], ['mobile', 0, 767]];
		// ex) setResponsiveType([['pc', 1024, false], ['tablet', 768, 1023], ['mobile', false, 767]])
		window.setResponsiveType = function setResponsiveType(types) {
			if (!types) {
				types = defaultType;
			}
			alltype = $.map(types, function (v, i) {
				return v[0];
			}).join(' ');

			bodySetType(type = typeCheck(types));
			$window.on('resize.res', (function () {
				var sw;
				return () => {
					if (beforeWidth !== $body.width()) {
						beforeWidth = $body.width();
						window.clearTimeout(sw);
						sw = window.setTimeout(function () {
							bodySetType(type = typeCheck(types));
							$window
								.trigger(type + '.resize')
							// .trigger('all.resize')
						}, 350);
					}
				}
			})()).on('load.res', (function () {
				$window.trigger(type + '.load');
			})());
			$(document).on('scroll', function () {
				var st = $window.scrollTop();
				$document.trigger(type + '.scroll', st)
			});

			function typeCheck(_types) {
				var typeName;
				var winWidth = $window.width();
				for (var i = 0, len = _types.length; i < len; i++) {
					if (winWidth >= _types[i][1] && winWidth <= _types[i][2]) {
						typeName = _types[i][0];
					}
				}
				return typeName;
			}

			function bodySetType(type) {
				$body.removeClass(alltype).addClass(type);
				window.responseType = type;
			}

			$(document).on('click', 'a, .clickable', function (e) {
				$(e.currentTarget).trigger(type + '.' + e.type, e);
				e.stopPropagation();
			})
		}
	}

	RESPONSIVE_WEB();

})(jQuery);


/*** etui ***/
(function ($) {
	let ui = window.etui = {};
	let $window = $(window);
	let $document = $(document);

	etui.headerFixed = function ({header}) {
		let isShow = false;
		TweenMax.set(header, {yPercent:-100});
		$window.on('mobile.scroll', function (_, st) {
			if(!isShow && st >= 330) {
				TweenMax.to(header, .45, {yPercent:0, ease:Power2.easeOut, display:'block'});
				isShow= true;
			}
			else if(isShow && st <= 330){
				TweenMax.to(header, .45, {yPercent:-100, ease:Power2.easeOut, display:'none'});
				isShow= false;
			}
		})
	}
	etui.more = function () {
		let $btn = $('.bx_info_programm .btn_list_more'), $cont = $('.info_programm .w_none');
		$btn.on('click', function (e) {
			e.preventDefault();
			$cont.removeClass('w_none')
			$btn.hide();
		})
		return { $btn, $cont }
	};
	let createSwiper = function ({ el, opt }) {
		let _defaultOpt = {
			initialSlide: 0,
			// autoHeight: true,
			// grabCursor: true,
			preloadImages: false,
			lazy: true,
			threshold: 5,
			speed: 400,
			on: {
				lazyImageReady() {
					this.updateAutoHeight(300);
				}
			}

		};
		return new Swiper(el, { ..._defaultOpt, ...opt, })
	};
	etui.listSwiper = function ({ el }) {
		let swiper, isAddSwipe = false;

		$window
			.on('mobile.ls.resize', function () {
				logger.log('resz')
				if (!isAddSwipe) {
					swiper = createSwiper({
						el,
						opt: {
							slidesPerView: 'auto',
							wrapperClass: 'info_programm',
						}
					});
					isAddSwipe = true;
				}
			})
			.on('pc-b.ls.resize pc-m.ls.resize pc-s.ls.resize', function () {
				if (isAddSwipe) {
					swiper.destroy();
					isAddSwipe = false;
				}
			})
			.trigger(responseType + '.ls.resize');

		return { swiper }
	}

	etui.datepicker = function ({ btn, input }) {
		let onFirstOpen = false;
		let $btn = $(btn);
		let $input = $(input).datepicker({
				beforeShow() {
					if (!onFirstOpen) {
						$input.datepicker('widget').wrap('<div class="datepicker"></div>')
						onFirstOpen = true;
					}
				},
				afterShow() {
					$input.datepicker('widget').append('<a class="btn_cal_close" href="#"><span class="hide_txt">닫기</span></a>')
				},
				onSelect(date, info) {
				    info.selectedMonth++;
					let year = info.selectedYear.toString();
					let month = info.selectedMonth.toString();
					let day = info.selectedDay.toString();
					month = month.length === 1 ? '0' + month : month;
					day = day.length === 1 ? '0' + day : day;
					$input.trigger("select", [year + month + day, info]);
				}
			});

		$(btn).on('click', function (e) {
			e.preventDefault();
			$input.datepicker("show");
		})
		$document.on('click', '.btn_cal_close', function (e) {
			e.preventDefault();
			$input.datepicker("hide");
		});
		return { btn, $input, onSelect(fn) { $input.on("select", fn) }}
	}

	etui.freeTab = (function () {
		function init({contain, wrap, item}) {
			let sw = new Swiper(contain, {
				slidesPerView: 'auto',
				threshold: 6,
				slideClass: item.replace('.', ''),
				wrapperClass: wrap.replace('.', ''),
				observeParents: true,
				observe: true,
			});
			let $item = $(contain).find(item);

			$item.on('click', function (e) {
				e.preventDefault();
				let goIndex = $item.index(this);
				$item.removeClass('on').eq(goIndex).addClass('on');
				window.setTimeout(function () {
					goIndex = (goIndex - 1) <= 0 ? 0 : goIndex - 1;
				}, 500)
				sw.slideTo(goIndex)
			})

			return sw;
		}
		return { init }
	})();

	etui.tab = (function () {
		let $wrap;

		function init({ wrap, tab, cont, onMoveAfter, initIndex = 0 }) {
			$wrap = $(wrap);
			return $wrap.map(function (i, v) {
				let $this = $(this);
				let data = {
					$ui: $this,
					$tabs: $this.find(tab),
					$conts: $this.find(cont),
					onMoveAfter,
					bindOn: () => _bindOn(data),
					bindOff: () => _bindOff(data),
					showTo: (idx) => _showTo(data, idx)
				};
				data.bindOn();
				data.$tabs.eq(initIndex).trigger('click');
				return data;
			});
		}

		function _bindOn({ $ui, $tabs, $conts, onMoveAfter }) {
			$tabs.off('.tab').on('click.tab', function (e) {
				let tabIndex = $tabs.index(this);
				e.preventDefault();
				$tabs.removeClass('on').eq(tabIndex).addClass('on');
				$conts.hide().eq(tabIndex).show();

				onMoveAfter ? onMoveAfter(tabIndex, $tabs.eq(tabIndex), $conts.eq(tabIndex)) : undefined;
			});
		}

		function _bindOff({ $tabs }) { $tabs.off('.tab'); }

		function _showTo({ $tabs }, tabIndex = 0) { $tabs.eq(tabIndex).trigger('click'); }

		return { init }
	})();
	etui.srcChange = function ({imgs}) {
		let $imgs = $(imgs);
		let isMobileSrc = false;

		$window
			.on('mobile.srcchange.resize', function () {
				if(!isMobileSrc){
					isMobileSrc= true;
					_changeImgSrc($imgs);
				}
			})
			.on('pc-b.srcchange.resize pc-m.srcchange.resize pc-s.srcchange.resize', function () {
				if(isMobileSrc){
					isMobileSrc= false;
					_changeImgSrc($imgs);
				}
			}).trigger(responseType + '.srcchange.resize')


		function _changeImgSrc($imgs) {
			$.each($imgs, function (i, img) {
				let $img = $(img);
				let src = $img.attr('src');
				let dataSrc = $img.attr('data-src');
				if(dataSrc) {
					$img.attr('src', dataSrc).attr('data-src', src);
				}
			})

		}
	}
	etui.ajaxPopup = (function () {
		var $dimm = $('.dimm');
		var $wrap_bx = $('#wrap_bx');
		var body = $("html, body");

		function init({ openSel, closeSel, popupSel }) {
			var beforeScrollTop = 0;
			$(document).on('click', openSel, function (e) {
				e.preventDefault();
				$.get($(this).attr('href'), function (html) {
					var $popup = $('<div>').html(html).find(popupSel);
					var scrollTop = $window.scrollTop();
					beforeScrollTop = $window.scrollTop();
					$window.scrollTop(0);
					$wrap_bx.addClass('popup--open')
					$dimm.show();
					$('body').append($popup);
					$popup.data('swiper', $.map($popup.find('.swiper-container'), function (v) {
						let sw;
						let $v = $(v);
						if($v.find('.swiper-slide').length <= 1){ return; }
						sw = createSwiper({
							el: v, opt: {
								pagination:{
								el: '.swiper-pagination',
								},
								slidesPerView: 1,
								navigation: {
									prevEl: '.swiper-button-prev',
									nextEl: '.swiper-button-next'
								},
								slideClass: 'swiper-slide',
								observe: true,
								observeParents: true
							}
						});
						return sw;
					}))
				});
			});
			$(document).on('click', closeSel, function (e) {

				popupClose($(this).closest(popupSel));
			})
			$dimm.on('click', function () {
				// popupClose($(popupSel))
			})

			function popupClose(popup) {
				var $popup = $(popup);
				var destroySwiper = $popup.data('swiper');
				$.each(destroySwiper, function (i, v) {
					if(v && v.destroy){
						v.destroy();
					}
				});
				$(popup).remove();
				$dimm.hide();
				$wrap_bx.removeClass('popup--open')
				window.setTimeout(function () {
					$window.scrollTop(beforeScrollTop);
				}, 0)
			}
		}

		return { init }
	})();
	// 현재 사용되지 않음
	// etui.popup = (function (closeSel) {
	// 	var $popup, $closeBtn, $lastOpenBtn;
	//
	// 	function open(sel) {
	// 		var popup = etui.popup;
	//
	// 		$popup = $(sel);
	// 		$closeBtn = $popup.find(closeSel);
	//
	// 		$lastOpenBtn = false;
	// 		if (this instanceof HTMLElement) {
	// 			$lastOpenBtn = $(this);
	// 		}
	//
	// 		$('.dimm').show();
	// 		var $img = $popup.find('.img_wrap img');
	// 		var dataSrc = $img.attr('data-src');
	// 		if (dataSrc) {
	// 			$img.attr('src', dataSrc).imagesLoaded(function () {
	// 				$img.removeAttr('data-src');
	// 				popupShow($popup);
	// 			})
	// 		}
	// 		else {
	// 			popupShow($popup);
	// 		}
	//
	// 		return false;
	// 	}
	//
	// 	function close(sel) {
	// 		$popup = $(sel);
	//
	// 		$('.dimm').hide();
	// 		$popup.hide();
	//
	// 		if ($lastOpenBtn && $lastOpenBtn.focus) {
	// 			$lastOpenBtn.focus();
	// 		}
	// 		// etlib.windowScroll.unBlock();
	// 	}
	//
	// 	function popupShow($popup) {
	// 		$popup.show();
	// 		etlib.windowScroll.block();
	//
	// 		// CLOSE BTN EVENT BIND
	// 		$popup.find(closeSel).add($('.dimm')).off('click.popup').one('click.popup', function (e) {
	// 			e.preventDefault();
	// 			etui.popup.close($popup);
	// 		});
	// 		// var calcTop = (($(window).height() - $popup.outerHeight()) / 2);
	// 		// calcTop = calcTop < 0 ? 0 : calcTop;
	// 		$popup.attr('tabindex', -1)
	//
	// 		// .css({ 'top': calcTop + 'px' })
	// 		$popup.focus();
	// 	}
	//
	// 	return { open, close }
	// })('.btn_close');

	//
	etui.displayToggle = (function () {
		return {
			init({ btn, cont, onOpen = () => {}, onClose = () => {} }) {
				var isBlock = false, $btn = $(btn), $cont = $(cont);
				let o = {
					close() {
						$cont.removeClass('open');
						$cont.hide()
						onClose();
					},
					open() {
						$cont.addClass('open')
						$cont.show()
						onOpen();
					},
					block() {
						isBlock = true;
					},
					unBlock() {
						isBlock = false;
					}
				};
				$btn.on('click', function (e) {
					e.preventDefault();
					if (!isBlock) {
						o.open();
						o.block();
					}
					else if (isBlock) {
						o.close();
						o.unBlock();
					}
				});

				return o;
			}
		}
	})()

	// 탑버튼
	etui.goTop = function ({ topBtn }) {
		var $topBtn = $(topBtn), isShow = false;
		$document
			.on('scroll.gotop', scrollShowHide);
		$topBtn.on('click', function (e) {
			var scrollTop = $window.scrollTop();
			e.preventDefault();
			var body = $("html, body");
			body.stop().animate({ scrollTop: 0 }, (80 + scrollTop * 0.18));
		});

		function scrollShowHide() {
			var scrollTop = $document.scrollTop();
			if (scrollTop > 400 && !isShow) {
				$topBtn.fadeIn(250);
				isShow = true;
			}
			else if (scrollTop <= 400 && isShow) {
				$topBtn.fadeOut(250);
				isShow = false;
			}
		}

		scrollShowHide();

		return { $topBtn }
	}


	etui.channelHelp = function ({btn}) {
		$(btn).on('click', function (e) {
			e.preventDefault();

			if(etUse.broadCastTab){
				etUse.broadCastTab[0].showTo(1);

				$('html, body').animate({scrollTop: $(etUse.broadCastTab[0].$tabs[1]).offset().top - (responseType === 'mobile' || responseType === 'pc-s'  ? 80 : 0)}, 400, function () {
					etUse.broadCastTab[0].$tabs[1].focus();
				})
			}
		})
	}

})(jQuery);


(function init() {
	window.etInit();
})();
